import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import {
    Button,
    TextField,
    FormControl,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Menu,
    Table,
    Grid,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    MenuItem,
    List,
    ListItem,
    Typography,
    Container,
    Chip,
    IconButton,
} from "@material-ui/core";
import { Add, Remove, Clear } from "@material-ui/icons";
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ocrTypes } from "../../constants";
import { useSnackbar } from "notistack";

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        "&:hover": {
            backgroundColor: grey[900],
        },
        marginLeft: 30,
        marginTop: 20,
        height: 50,
    },
}))(Button);

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: 100,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,
    },
    cards: {
        marginTop: 50,
        marginLeft: 25
    },
});
function ProjectHome(props) {
    const classes = useStyles({
        listItem: {
            padding: '8px 16px', // Adjust padding as needed
        },
    });
    const { enqueueSnackbar } = useSnackbar();
    const [projects, setProjects] = useState([]);
    const [tags, setTags] = useState({
        Untagged: {
            selected: false,
            count: 0,
        },
    });
    const [name, setName] = useState("");
    const [translate, setTranslate] = useState(false);
    const [sorting, setSorting] = useState(false);
    const [open, setOpen] = useState(false);
    const [openLayoutNext, setOpenLayoutNext] = useState(false);
    const [openLexNext, setOpenLexNext] = useState(false);
    const [version, setVersion] = useState("3");
    const [promptSelect, setPromptSelect] = useState("prompt1");
    const [lexModel, setLexModel] = useState("openai:gpt-3.5-turbo");
    const [lexMultiplier, setLexMultiplier] = useState(1);
    const [projectType, setProjectType] = useState("extraction");
    const [ocrType, setOcrType] = useState("google");
    const [type, setType] = useState("card");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newTagName, setNewTagName] = useState("");
    const [selectedProjectsForTagging, setSelectedProjectsForTagging] = useState([]);
    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openNewTagDialog, setOpenNewTagDialog] = useState(false);


    const filteredProjects = projects.filter(project =>
        project.name.toLowerCase().includes(search.toLowerCase()) || project._id.toLowerCase().includes(search.toLowerCase())
    );

    const handleProjectSelectForTagging = (e, id) => {
        if (e.target.checked) {
            setSelectedProjectsForTagging([...selectedProjectsForTagging, id]);
        } else {
            setSelectedProjectsForTagging(selectedProjectsForTagging.filter((item) => item !== id));
        }
    };

    // Handle change page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle change rows per page
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // eslint-disable-next-line no-template-curly-in-string
    const [prompt, setPrompt] = useState("Extract information as a JSON:\nExtract: fieldName ()\n${ocr}");

    const [inputFields, setInputFields] = useState([
        { id: uuidv4(), field: "", validationType: "", description: "", hallucinationCheck: true },
    ]);
    const [validationInputFields, setValidationInputFields] = useState([
        { id: uuidv4(), field: "", validationType: "" },
    ]);


    const updatePromptValue = (fieldValues, promptStyle = promptSelect) => {
        let result;
        if (promptStyle === "prompt1") {
            result = fieldValues.map(({ field, description }) => `${field} (${description})`).join(', ');
        } else if (promptStyle === "prompt2") {
            result = fieldValues.map(({ field }) => `${field}`).join(', ');
        }
        if (result) {
            setPrompt(`Extract information as a JSON:\nExtract: ${result}\n\${ocr}`);
        }
    }

    const handleChangeInput = (id, event) => {
        const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
                i[event.target.name] = event.target.value;
            }
            return i;
        });
        setInputFields(newInputFields);
    };

    const handleValidationChangeInput = (id, event) => {
        const newInputFields = validationInputFields.map((i) => {
            if (id === i.id) {
                i[event.target.name] = event.target.value;
            }
            return i;
        });
        setValidationInputFields(newInputFields);
    };

    const handleChangeLexInput = (id, event) => {
        const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
                i[event.target.name] = event.target.value;
            }
            return i;
        });
        setInputFields(newInputFields);
        updatePromptValue(newInputFields);
    };

    const handleHallucinationCheck = (id, e) => {
        const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
                i[e.target.name] = e.target.checked;
            }
            return i;
        });
        setInputFields(newInputFields);
    }

    const handleChangeLexPrompt = (event) => {
        setPrompt(event.target.value);
    };

    const handleAddFields = () => {
        setInputFields([
            ...inputFields,
            { id: uuidv4(), field: "", validationType: "", description: "", hallucinationCheck: true },
        ]);
    };

    const handleAddValidationFields = () => {
        setValidationInputFields([
            ...validationInputFields,
            { id: uuidv4(), field: "", validationType: "" },
        ]);
    };

    const handleRemoveFields = (id) => {
        const values = [...inputFields];
        values.splice(
            values.findIndex((value) => value.id === id),
            1
        );
        setInputFields(values);
        updatePromptValue(values);
    };

    const handleRemoveValidationFields = (id) => {
        const values = [...validationInputFields];
        values.splice(
            values.findIndex((value) => value.id === id),
            1
        );
        setValidationInputFields(values);
    };

    const processProjectData = (projects) => {
        if (!projects) return;
        if (projects.length === 0) return;

        const TAGS = {
            Untagged: {
                selected: false,
                count: 0,
            },
        };

        for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (!project.tags) continue;
            for (let j = 0; j < project.tags.length; j++) {
                if (TAGS[project.tags[j]]) {
                    TAGS[project.tags[j]].count += 1;
                } else {
                    TAGS[project.tags[j]] = {
                        selected: false,
                        count: 1,
                    }
                }
            }
            if (project.tags.length === 0) {
                TAGS["Untagged"].count += 1;
            }
        }
        setTags(TAGS);
    }

    const getProjects = () => {
        axios
            .get("/api/projects/list?query=deployment")
            .then((data) => {
                processProjectData(data.data);
                setProjects(data.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        getProjects();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleBack = () => {
        setOpen(true);
        setOpenLayoutNext(false);
        setOpenLexNext(false);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenLayoutNext(false);
        setOpenLexNext(false);
    };

    const handleNext = () => {
        setOpen(false);
        if (version === 'lex') {
            setOpenLexNext(true);
        } else {
            setOpenLayoutNext(true);
        }
    };

    const handleBenchmarkClick = (id, name, projectType) => {
        props.history.push({
            pathname: '/project/benchmarks',
            state: {
                id: id,
                name: name,
                docType: projectType,
                benchmarkCategory: 'project',
            },
        });
    };

    const createProject = () => {
        const data = {
            project: {
                name,
                inputFields: inputFields,
                validationInputFields,
                projectType,
                ocrType,
                type,
                status: version === 'lex' ? "model_generated" : "created",
                translate,
                sorting,
                version,
                prompt,
                lexModel,
                lexMultiplier
            },
        };
        axios
            .post("/api/projects/create", data)
            .then((res) => {
                enqueueSnackbar("Project created successfully", {
                    variant: "success",
                });
                setProjects([]);
                setName("");
                setInputFields([{ id: uuidv4(), field: "", validationType: "", description: "", hallucinationCheck: true }]);
                setValidationInputFields([
                    { id: uuidv4(), field: "", validationType: "" },
                ]);
                getProjects();
                setOpenLayoutNext(false);
                setOpenLexNext(false);
                window.location.reload();
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data, {
                    variant: "error",
                });
            });
    };

    const addTagToProjects = (projectIds, tag) => {
        if (projectIds.length === 0) {
            enqueueSnackbar("No projects selected", {
                variant: "error",
            });
            return;
        }
        if (!tag || tag === "") {
            enqueueSnackbar("No tag selected", {
                variant: "error",
            });
            return;
        }
        axios
            .post("/api/projects/addTagToProjects", {
                projectIds,
                tag,
            })
            .then((res) => {
                enqueueSnackbar(res.data, {
                    variant: "success",
                });
                getProjects();
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data, {
                    variant: "error",
                });
            });
    };

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleTranslate = (e) => {
        setTranslate(e.target.checked)
    }

    const handleSorting = (e) => {
        setSorting(e.target.checked)
    }

    const handleVersion = (e) => {
        setVersion(e.target.value)
    }

    const handleLexModel = (e) => {
        setLexModel(e.target.value)
    }

    const handleLexMultiplier = (e) => {
        setLexMultiplier(e.target.value)
    }

    const handleType = (e) => {
        setType(e.target.value)
    }

    const handleProjectType = (e) => {
        setProjectType(e.target.value);
    };

    const handleOcrType = (e) => {
        setOcrType(e.target.value);
    };

    const handlePromptSelect = (e) => {
        setPromptSelect(e.target.value);
        updatePromptValue(inputFields, e.target.value);
    };

    const handleClick = (projectId) => {
        props.history.push({
            pathname: '/project',
            state: {
                project: projectId,
            },
        })
    };


    return (
        <div>
            <ColorButton
                startIcon={<Add />}
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
            >
                Create New Project
            </ColorButton>
            <ColorButton
                startIcon={<Add />}
                onClick={() => setOpenNewTagDialog(true)}
                variant="contained"
                color="primary"
            >
                Add tag to projects
            </ColorButton>
            <div
                style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    paddingLeft: 30,
                    paddingTop: 30,
                }}
            >
                Existing Projects
            </div>
            <div
                style={{
                    fontSize: 25,
                    fontWeight: "bold",
                    paddingLeft: 30,
                    paddingTop: 30,
                    justifyContent: 'left',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span style={
                    {
                        fontSize: 30,
                        fontWeight: "bold",
                    }
                }>All Tags</span>
                <Clear
                    onClick={() => {
                        const newTags = { ...tags };
                        Object.keys(newTags).forEach((tag) => {
                            newTags[tag].selected = false;
                        });
                        setTags(newTags);
                    }}
                    style={{ marginLeft: 20, cursor: 'pointer' }}
                />
            </div>

            <div style={{ paddingLeft: 30, marginTop: 15 }}>
                {Object.keys(tags).map((tag) => (
                    <Chip
                        label={`${tag} (${tags[tag].count})`}
                        clickable
                        color={tags[tag].selected ? "primary" : "default"}
                        onClick={() => {
                            const newTags = { ...tags };
                            newTags[tag].selected = !newTags[tag].selected;
                            setTags(newTags);
                        }}
                        style={{ margin: 5 }}
                    />
                ))}
            </div>

            <div
                style={{
                    fontSize: 25,
                    fontWeight: "bold",
                    paddingLeft: 30,
                    paddingTop: 30,
                }}
            >
                All Projects
            </div>

            <div style={{ paddingLeft: 15, paddingRight: 15, maxWidth: '100%', alignContent: 'center', alignItems: 'center' }}>
                <List className={classes.list}>
                    {projects.length === 0 ? (
                        "No Existing Projects"
                    ) : (
                        <Grid container spacing={2}>
                            {projects.map((project, index) => (
                                (Object.keys(tags).filter((tag) => tags[tag].selected).length === 0 ||
                                    project.tags.some((tag) => tags[tag].selected) ||
                                    (project.tags.length === 0 && tags["Untagged"].selected)) && (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <ListItem divider>
                                            <div className={classes.projectInfo} style={{ width: '80%' }}>
                                                <Typography variant="h5" component="h2" style={{ cursor: 'pointer' }} onClick={() => handleClick(project._id)}>
                                                    {project.name}  {project.isLocked && (
                                                        <Tooltip title="Project is locked, can only be modified by superAdmin only">
                                                            <LockIcon />
                                                        </Tooltip>
                                                    )}
                                                </Typography>

                                                {(project.tags.length !== 0 && <Typography variant="body2" className={classes.secondaryText}>
                                                    Tags {project.tags && project.tags.map((tag) => (
                                                        <Chip
                                                            key={tag}
                                                            label={tag}
                                                            clickable
                                                            size="small"
                                                            onClick={() => {
                                                                const newTags = { ...tags };
                                                                newTags[tag].selected = !newTags[tag].selected;
                                                                setTags(newTags);
                                                            }}
                                                            style={{ margin: 3, padding: 0 }}
                                                        />
                                                    ))}
                                                </Typography>)}
                                                <div style={{ display: "flex", alignItems: 'flex-start' }}>
                                                    <Typography variant="body2" className={classes.secondaryText} color="textSecondary">
                                                        {project.type === 'card' ? "CARD" : project.type === 'passport' ? "PASSPORT" : "DOCUMENT"} | {project.version} | {project.ocr}
                                                    </Typography>
                                                </div>

                                                <div style={{ display: "flex", alignItems: 'flex-start' }}>

                                                    <Typography variant="body2" className={classes.secondaryText} color="textSecondary" style={{ cursor: 'pointer' }} onClick={() => {
                                                        enqueueSnackbar(`${project._id} copied to clipboard`, {
                                                            variant: "success",
                                                            autoHideDuration: 800,
                                                        });
                                                        navigator.clipboard.writeText(project._id);
                                                    }}>
                                                        {project._id}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className={classes.projectInfo} style={{ width: '20%' }}>
                                                <Button
                                                    size="small"
                                                    onCl
                                                    fullWidth
                                                    style={{ border: '1px solid #000', marginBottom: 10 }}
                                                    onClick={() => handleClick(project._id)}
                                                >
                                                    View Project
                                                </Button>
                                                <Button
                                                    size="small"
                                                    onCl
                                                    fullWidth
                                                    style={{ border: '1px solid #000' }}
                                                    onClick={() => handleBenchmarkClick(project._id, project.name, project.type)}
                                                >
                                                    Benchmarks
                                                </Button>
                                            </div>
                                        </ListItem>
                                    </Grid>
                                )
                            ))}
                        </Grid>
                    )}
                </List>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="create-project"
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogTitle id="create-project">Create new project</DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        autoFocus
                        margin="normal"
                        id="name"
                        label="Project Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleName}
                        value={name}
                    />
                    <FormControl variant="outlined" style={{ marginTop: 8 }}>
                        <TextField
                            select
                            value={projectType}
                            onChange={handleProjectType}
                            label="Type"
                            variant="outlined"
                            style={{ width: 250 }}
                        >
                            <MenuItem value="extraction">Field Extraction</MenuItem>
                            <MenuItem value="validation">Field Validation</MenuItem>
                            <MenuItem value="both">Field Extraction and Validation</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl variant="outlined" style={{ marginTop: 14 }}>
                        <TextField
                            select
                            value={ocrType}
                            onChange={handleOcrType}
                            label="OCR Type"
                            variant="outlined"
                            style={{ width: 250 }}
                        >
                            {Object.keys(ocrTypes).map((ocrType) => (
                                <MenuItem value={ocrType}>{ocrTypes[ocrType]}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl variant="outlined" style={{ marginTop: 14 }}>
                        <TextField
                            select
                            value={version}
                            onChange={handleVersion}
                            label="Version"
                            variant="outlined"
                            style={{ width: 250 }}
                        >
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="lex">lex</MenuItem>
                        </TextField>
                    </FormControl>
                    {version === "lex" ? (
                        <FormControl variant="outlined" style={{ marginTop: 14 }}>
                            <TextField
                                value={lexModel}
                                onChange={handleLexModel}
                                label="Lex Model Name"
                                variant="outlined"
                                style={{ width: 250 }}
                            />
                        </FormControl>
                    ) : (
                        <span>&nbsp;</span>
                    )}
                    {version === "lex" ? (
                        <FormControl variant="outlined" style={{ marginTop: 14 }}>
                            <TextField
                                value={lexMultiplier}
                                onChange={handleLexMultiplier}
                                label="Lex Multiplier"
                                variant="outlined"
                                style={{ width: 250 }}
                            />
                        </FormControl>
                    ) : (
                        <span>&nbsp;</span>
                    )}
                    <FormControl variant="outlined" style={{ marginTop: 14 }}>
                        <TextField
                            select
                            value={type}
                            onChange={handleType}
                            label="Type"
                            variant="outlined"
                            style={{ width: 250 }}
                        >
                            <MenuItem value="card">Card</MenuItem>
                            <MenuItem value="doc">Document</MenuItem>
                            <MenuItem value="passport">Passport</MenuItem>
                        </TextField>
                    </FormControl>
                    <div>
                        <Checkbox
                            checked={translate}
                            onChange={handleTranslate}
                            inputProps={{ "aria-label": "primary checkbox" }}
                            required
                        />
                        <span>Enable Translation</span>
                    </div>
                    <div>
                        <Checkbox
                            checked={sorting}
                            onChange={handleSorting}
                            inputProps={{ "aria-label": "primary checkbox" }}
                            required
                        />
                        <span>Enable Sorting</span>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleNext} variant="contained" color="default">
                        Next
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openLayoutNext}
                onClose={handleClose}
                aria-labelledby="create-project"
                maxWidth="xl"
            >
                <DialogContent>
                    <Container>
                        {projectType !== "validation" ? (
                            <h1>Extraction Fields</h1>
                        ) : (
                            <h1>Validation Fields</h1>
                        )}
                        <form>
                            {inputFields.map((inputField) => (
                                <div key={inputField.id} style={{ marginBottom: 10 }}>
                                    <FormControl style={{ marginTop: 15 }}>
                                        <TextField
                                            name="field"
                                            label="Field Name*"
                                            variant="outlined"
                                            value={inputField.field}
                                            onChange={(event) =>
                                                handleChangeInput(inputField.id, event)
                                            }
                                        />
                                    </FormControl>
                                    <span>&nbsp;</span>
                                    {projectType === "validation" ? (
                                        <FormControl style={{ marginTop: 15 }}>
                                            <TextField
                                                select
                                                name="validationType"
                                                variant="outlined"
                                                value={inputField.validationType}
                                                onChange={(event) =>
                                                    handleChangeInput(inputField.id, event)
                                                }
                                                label="Validation Type"
                                                style={{ width: 250 }}
                                            >
                                                <MenuItem value="strict">Strict</MenuItem>
                                                <MenuItem value="fuzzy">Fuzzy Match</MenuItem>
                                                <MenuItem value="editDistance1">
                                                    Edit Distance 1
                                                </MenuItem>
                                                <MenuItem value="editDistance2">
                                                    Edit Distance 2
                                                </MenuItem>
                                                <MenuItem value="editDistance3">
                                                    Edit Distance 3
                                                </MenuItem>
                                            </TextField>
                                        </FormControl>
                                    ) : (
                                        <span>&nbsp;</span>
                                    )}
                                    <IconButton
                                        disabled={inputFields.length === 1}
                                        onClick={() => handleRemoveFields(inputField.id)}
                                    >
                                        <Remove />
                                    </IconButton>
                                    <IconButton onClick={handleAddFields}>
                                        <Add />
                                    </IconButton>
                                </div>
                            ))}
                        </form>
                        {projectType === "both" ? (
                            <h1>Validation Fields</h1>
                        ) : (
                            <span>&nbsp;</span>
                        )}
                        {projectType === "both" ? (
                            <form>
                                {validationInputFields.map((inputField) => (
                                    <div key={inputField.id} style={{ marginBottom: 10 }}>
                                        <FormControl style={{ marginTop: 15 }}>
                                            <TextField
                                                name="field"
                                                label="Field Name*"
                                                variant="outlined"
                                                value={inputField.field}
                                                onChange={(event) =>
                                                    handleValidationChangeInput(inputField.id, event)
                                                }
                                            />
                                        </FormControl>
                                        <span>&nbsp;</span>
                                        <FormControl style={{ marginLeft: 30, marginTop: 15 }}>
                                            <TextField
                                                select
                                                name="validationType"
                                                variant="outlined"
                                                value={inputField.validationType}
                                                onChange={(event) =>
                                                    handleValidationChangeInput(inputField.id, event)
                                                }
                                                label="Validation Type"
                                                style={{ width: 250 }}
                                            >
                                                <MenuItem value="strict">Strict</MenuItem>
                                                <MenuItem value="fuzzy">Fuzzy Match</MenuItem>
                                                <MenuItem value="editDistance1">
                                                    Edit Distance 1
                                                </MenuItem>
                                                <MenuItem value="editDistance2">
                                                    Edit Distance 2
                                                </MenuItem>
                                                <MenuItem value="editDistance3">
                                                    Edit Distance 3
                                                </MenuItem>
                                            </TextField>
                                        </FormControl>
                                        <IconButton
                                            disabled={validationInputFields.length === 1}
                                            onClick={() =>
                                                handleRemoveValidationFields(inputField.id)
                                            }
                                        >
                                            <Remove />
                                        </IconButton>
                                        <IconButton onClick={handleAddValidationFields}>
                                            <Add />
                                        </IconButton>
                                    </div>
                                ))}
                            </form>
                        ) : (
                            <span>&nbsp;</span>
                        )}
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleBack} variant="contained" color="primary">
                        Back
                    </Button>
                    <Button onClick={createProject} variant="contained" color="default">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openLexNext}
                onClose={handleClose}
                aria-labelledby="create-project"
                maxWidth="xl"
            >
                <DialogTitle id="configure-prompt" style={{ marginLeft: 20 }} >Configure Prompt</DialogTitle>
                <DialogContent>
                    <Container>
                        <TextField
                            select
                            value={promptSelect}
                            onChange={handlePromptSelect}
                            label="Prompt Select"
                            variant="outlined"
                            style={{ width: 250 }}
                        >
                            <MenuItem value="prompt1">Standard Prompt 1</MenuItem>
                            <MenuItem value="prompt2">Standard Prompt 2</MenuItem>
                            <MenuItem value="custom">Custom Prompt</MenuItem>
                        </TextField>
                        <form>
                            {inputFields.map((lexInputField) => (
                                <div key={lexInputField.id} style={{ marginBottom: 10 }}>
                                    <FormControl style={{ marginTop: 15 }}>
                                        <TextField
                                            name="field"
                                            label="Field Name*"
                                            variant="outlined"
                                            value={lexInputField.field}
                                            onChange={(event) =>
                                                handleChangeLexInput(lexInputField.id, event)
                                            }
                                        />
                                    </FormControl>
                                    <span>&nbsp;</span>
                                    <FormControl style={{ marginLeft: 30, marginTop: 15 }}>
                                        <TextField
                                            name="description"
                                            label="Field Desciption"
                                            variant="outlined"
                                            value={lexInputField.description}
                                            onChange={(event) =>
                                                handleChangeLexInput(lexInputField.id, event)
                                            }
                                        />
                                    </FormControl>
                                    <span>&nbsp;</span>
                                    <FormControl style={{ marginLeft: 30, marginTop: 15 }}>
                                        <div>
                                            <Checkbox
                                                name="hallucinationCheck"
                                                label="hallucinationCheck"
                                                variant="outlined"
                                                value={lexInputField.hallucinationCheck}
                                                checked={lexInputField.hallucinationCheck}
                                                onChange={(event) =>
                                                    handleHallucinationCheck(lexInputField.id, event)
                                                }
                                            />
                                            <span>Hallucination Check</span>
                                        </div>
                                    </FormControl>
                                    <span>&nbsp;</span>
                                    <IconButton
                                        disabled={inputFields.length === 1}
                                        onClick={() => handleRemoveFields(lexInputField.id)}
                                    >
                                        <Remove />
                                    </IconButton>
                                    <IconButton onClick={handleAddFields}>
                                        <Add />
                                    </IconButton>
                                </div>
                            ))}
                        </form>
                        <br></br>
                        {promptSelect === "custom" ? (<FormControl fullWidth style={{ marginTop: 15 }}>
                            <TextField
                                name="customPrompt"
                                label="Custom Prompt"
                                variant="outlined"
                                value={prompt}
                                multiline
                                onChange={(event) =>
                                    handleChangeLexPrompt(event)
                                }
                            />
                        </FormControl>) : (<code>{prompt}</code>)}
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleBack} variant="contained" color="primary">
                        Back
                    </Button>
                    <Button onClick={createProject} variant="contained" color="default">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openNewTagDialog}
                onClose={() => setOpenNewTagDialog(false)}
                aria-labelledby="create-group-projects"
                fullWidth={true}
                maxWidth='md'
            >
                <DialogTitle id="add-projects">Select projects</DialogTitle>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    marginLeft: 25,
                }}>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => setAnchorEl(event.currentTarget)}>
                        Select existing tag
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={(event) => setAnchorEl(null)}
                    >
                        {Object.keys(tags).filter((tag) => tag !== "Untagged").map((tag) => (
                            <MenuItem
                                onClick={() => {
                                    setNewTagName(tag);
                                    setAnchorEl(null);
                                }}
                            >
                                {tag}
                            </MenuItem>
                        ))}
                    </Menu>
                    <TextField
                        required
                        margin="normal"
                        id="tag"
                        label="Enter a new Tag"
                        type="text"
                        variant="outlined"
                        value={newTagName}
                        onChange={(e) => setNewTagName(e.target.value)}
                        style={{ marginLeft: 25, marginRight: 25 }}
                    />
                    {
                        newTagName && !Object.keys(tags).includes(newTagName) ? "(New Tag)" : ""
                    }
                </div>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={search}
                    style={{ marginLeft: 25, marginRight: 25 }}
                    onChange={e => setSearch(e.target.value)}
                />
                <div style={{ marginLeft: 25, marginTop: 20 }}>
                    {selectedProjectsForTagging.map((project, index) => (
                        <Chip key={index} label={project} title={project} style={{ marginRight: 10, marginTop: 10 }} />
                    ))}
                </div>
                <DialogContent>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            {filteredProjects.length === 0
                                ? "No Projects Found"
                                : filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            <Typography>
                                                {project.name}
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Id: {project._id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox
                                                checked={selectedProjectsForTagging.includes(project._id)}
                                                onChange={(e) => handleProjectSelectForTagging(e, project._id)}
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                required
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={filteredProjects.length}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenNewTagDialog(false) }} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            addTagToProjects(selectedProjectsForTagging, newTagName);
                            setOpenNewTagDialog(false);
                            setNewTagName("");
                            setSelectedProjectsForTagging([]);
                            setSearch("");
                        }}
                        className="btn btn-primary"
                    >Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ProjectHome
