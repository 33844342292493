import React from 'react';
import StatusCard from './StatusCard';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Chip } from '@material-ui/core';

const Header = (props) => {
    const { name, project, ocr, completedOcr, completedAnnotations, images, version, prompt, lexModel, lexMultiplier, fieldsForHallucinationCheck, isLocked, tags, deleteTagSetter } = props;
    return (
        <>
            <span>&nbsp;&nbsp;</span>
            <div
                style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    paddingLeft: 30,
                }}
            >
                PROJECT: {name} ({project})
            </div>
            <div
                style={{
                    fontSize: 20,
                    paddingLeft: 30,
                    paddingBottom: 30,
                }}
            >
                Current status: {isLocked ? "Locked" : "Unlocked"} ({isLocked ? <LockIcon /> : <LockOpenIcon />})
            </div>
            <div
                style={{
                    fontSize: 20,
                    paddingLeft: 30,
                    paddingBottom: 30,
                }}>
                Tags : {tags.map((tag, index) => {
                    return <Chip key={index} label={tag} style={{ margin: 5 }} onDelete={()=>{
                        deleteTagSetter(tag);
                    }} />
                })}
            </div>
            <div
                style={{
                    fontSize: 15,
                    fontWeight: "bold",
                    paddingLeft: 30,
                    paddingBottom: 30,
                }}
            >
                OCR: {ocr
                    ? ocr.toUpperCase()
                    : "GOOGLE"}
            </div>
            <div
                style={{
                    fontSize: 15,
                    fontWeight: "bold",
                    paddingLeft: 30,
                    paddingBottom: 10,
                }}
            >
                Version: {version ? version : "1"}
            </div>
            {prompt && version === 'lex' ? <div
                style={{
                    fontSize: 15,
                    paddingLeft: 30,
                    paddingBottom: 10,
                    paddingRight: 30,
                }}
            >
                <p style={{ fontWeight: "bold" }}>Prompt: </p>{prompt}
            </div> : null}
            {lexModel && version === 'lex' ? <div
                style={{
                    fontSize: 15,
                    paddingLeft: 30,
                    paddingBottom: 10,
                    paddingRight: 30,
                }}
            >
                <p style={{ fontWeight: "bold" }}>Lex Model Name: </p>{lexModel}
            </div> : null}
            {lexModel && version === 'lex' ? <div
                style={{
                    fontSize: 15,
                    paddingLeft: 30,
                    paddingBottom: 10,
                    paddingRight: 30,
                }}
            >
                <p style={{ fontWeight: "bold" }}>Lex Multiplier: </p>{lexMultiplier}
            </div> : null}
            {lexModel && version === 'lex' && fieldsForHallucinationCheck.length !== 0 ? <div
                style={{
                    fontSize: 15,
                    paddingLeft: 30,
                    paddingBottom: 10,
                    paddingRight: 30,
                }}
            >
                <p style={{ fontWeight: "bold" }}>Fields for hallucination check: </p>[{fieldsForHallucinationCheck.join(", ")}]
            </div> : null}
            <StatusCard completedOcr={completedOcr} completedAnnotations={completedAnnotations} totalImages={images.length} />
        </>
    )
}

export default Header